<template>
<div>
   <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
        <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="iq-navbar-logo d-flex align-items-center justify-content-between">
            <i class="ri-menu-line wrapper-menu"></i>
            <router-link :to="{name: 'product.listproduct'}" class="header-logo">
                <img :src="logo" class="img-fluid rounded-normal" alt="logo">
            </router-link>
            <h5 class="logo-title ml-3">COUPLUS</h5>
        </div>
            <div class="iq-search-bar device-search mb-0 mt-3 d-flex justify-content-start">
                <b-button variant="outline-warning" class="mb-1 mr-2 d-none d-md-block" href="https://sponge-sound-7be.notion.site/COUPLUS-4e097bd8dd084166aed6438fdacc1c89" target="_blank">
                    쿠플러스 사용자 메뉴얼
                </b-button>
                <b-button variant="outline-success" class="mb-1 mr-2 d-none d-md-block" href="https://chrome.google.com/webstore/detail/couplus/fdfjeigdbhekohnmjbggdcjomicdcjbp?hl=ko&authuser=0" target="_blank">
                    확장 프로그램 다운로드
                </b-button>
            </div>
            <div class="d-flex justify-content-end align-items-center d-none d-md-block">
<!--                <b-navbar-toggle target="nav-collapse" class="navbar-toggler d-none d-md-block">-->
<!--                <i class="ri-menu-3-line d-none d-md-block"></i>-->
<!--                </b-navbar-toggle>-->
                <b-collapse id="nav-collapse" is-nav>
                    <ul class="navbar-nav ml-auto navbar-list align-items-center">
                        <!-- <li class="nav-item nav-icon dropdown" v-nav-toggle>
                            <a href="#" class="search-toggle dropdown-toggle btn border add-btn"
                                id="dropdownMenuButton02" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <img src="@/assets/images/small/flag-01.png" alt="img-flag"
                                    class="img-fluid image-flag mr-2">En
                            </a>
                            <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                <div class="card shadow-none m-0">
                                    <div class="card-body p-3">
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-02.png" alt="img-flag"
                                                class="img-fluid mr-2">French</a>
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-03.png" alt="img-flag"
                                                class="img-fluid mr-2">Spanish</a>
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-04.png" alt="img-flag"
                                                class="img-fluid mr-2">Italian</a>
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-05.png" alt="img-flag"
                                                class="img-fluid mr-2">German</a>
                                        <a class="iq-sub-card" href="#"><img
                                                src="@/assets/images/small/flag-06.png" alt="img-flag"
                                                class="img-fluid mr-2">Japanese</a>
                                    </div>
                                </div>
                            </div>
                        </li> -->

                        <!-- <li>
                            <a href="#" class="btn border add-btn shadow-none mx-2 d-none d-md-block"
                                v-b-modal.new-order data-target="#new-order"><i class="las la-plus mr-2"></i>New
                                Order</a>
                        </li> -->
                        <!-- <li class="nav-item nav-icon search-content" v-nav-toggle>
                            <a href="#" class="search-toggle rounded dropdown-toggle" id="dropdownSearch" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="ri-search-line"></i>
                            </a>
                            <div class="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                                <form action="#" class="searchbox p-2">
                                    <div class="form-group mb-0 position-relative">
                                        <input type="text" class="text search-input font-size-12"
                                            placeholder="type here to search...">
                                        <a href="#" class="search-link"><i class="las la-search"></i></a>
                                    </div>
                                </form>
                            </div>
                        </li> -->
                        <!-- <li class="nav-item nav-icon dropdown" v-nav-toggle>
                            <a href="#" class="search-toggle dropdown-toggle" id="dropdownMenuButton2"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-mail">
                                    <path
                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                    </path>
                                    <polyline points="22,6 12,13 2,6"></polyline>
                                </svg>
                                <span class="bg-primary"></span>
                            </a>
                            <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                <div class="card shadow-none m-0">
                                    <div class="card-body p-0 ">
                                        <div class="cust-title p-3">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h5 class="mb-0">All Messages</h5>
                                                <a class="badge badge-primary badge-card" href="#">3</a>
                                            </div>
                                        </div>
                                        <div class="px-3 pt-0 pb-0 sub-card">
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3 border-bottom">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/01.jpg" alt="01">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Emma Watson</h6>
                                                            <small class="text-dark"><b>12 : 47 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3 border-bottom">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/02.jpg" alt="02">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Ashlynn Franci</h6>
                                                            <small class="text-dark"><b>11 : 30 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/03.jpg" alt="03">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Kianna Carder</h6>
                                                            <small class="text-dark"><b>11 : 21 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <a class="right-ic btn btn-primary btn-block position-relative p-2" href="#"
                                            role="button">
                                            View All
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li> -->
                        <!-- <li class="nav-item nav-icon dropdown" v-nav-toggle>
                            <a href="#" class="search-toggle dropdown-toggle" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-bell">
                                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                </svg>
                                <span class="bg-primary "></span>
                            </a>
                            <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div class="card shadow-none m-0">
                                    <div class="card-body p-0 ">
                                        <div class="cust-title p-3">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h5 class="mb-0">Notifications</h5>
                                                <a class="badge badge-primary badge-card" href="#">3</a>
                                            </div>
                                        </div>
                                        <div class="px-3 pt-0 pb-0 sub-card">
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3 border-bottom">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/01.jpg" alt="01">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Emma Watson</h6>
                                                            <small class="text-dark"><b>12 : 47 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3 border-bottom">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/02.jpg" alt="02">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Ashlynn Franci</h6>
                                                            <small class="text-dark"><b>11 : 30 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="#" class="iq-sub-card">
                                                <div class="media align-items-center cust-card py-3">
                                                    <div class="">
                                                        <img class="avatar-50 rounded-small"
                                                            src="@/assets/images/user/03.jpg" alt="03">
                                                    </div>
                                                    <div class="media-body ml-3">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <h6 class="mb-0">Kianna Carder</h6>
                                                            <small class="text-dark"><b>11 : 21 pm</b></small>
                                                        </div>
                                                        <small class="mb-0">Lorem ipsum dolor sit amet</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <a class="right-ic btn btn-primary btn-block position-relative p-2" href="#"
                                            role="button">
                                            View All
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li> -->
                        <li><a><img src="@/assets/images/small/ch.png" class="img-fluid rounded" alt="user" v-b-modal.channelTalk></a>
                            <b-modal id="channelTalk" ok-only title="채널문의" size="sm">
                                <div class="iq-search-bar device-search mb-0 mt-3 d-flex justify-content-center">
                                    <b-button variant="outline-warning" class="mb-1 mr-2 d-none d-md-block" href="http://pf.kakao.com/_AxcWvxj" target="_blank">
                                        쿠패스 문의 (구매, 배송대행 관련문의)
                                    </b-button>
                                    <b-button variant="outline-success" class="mb-1 mr-2 d-none d-md-block" href="http://pf.kakao.com/_xhDjxeb" target="_blank">
                                        쿠플러스 프로그램 문의
                                    </b-button>
                                </div>
                            </b-modal>
                        </li>
                        <li class="nav-item nav-icon dropdown caption-content" v-nav-toggle>
                            <a href="#" class="search-toggle dropdown-toggle" id="dropdownMenuButton4"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <!-- <img src="@/assets/images/user/1.png" class="img-fluid rounded" alt="user"> -->

                                <!-- <p class="rounded-top img-fluid mb-1 ml-0">Company ID : Test</p> -->
                                <!-- <p class="rounded-top img-fluid mb-0 ml-0" v-if="showSellerInfo">Company ID : {{companyid}} &nbsp;</p> -->
                                <b-button variant="outline-primary" class="change-button">계정관리</b-button>
                            </a>
                            <div class="iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div class="card shadow-none m-0">
                                    <div class="card-body p-0 text-center">
                                        <div class="media-body profile-detail text-center">
                                            <img src="@/assets/images/page-img/profile-bg.jpg" alt="profile-bg"
                                                class="rounded-top img-fluid mb-4">
                                        </div>
                                        <div class="p-3">
                                            <p class="mb-0" v-if="showSellerInfo">{{companyid}} / {{suppliercode}}</p>
                                            <h5 class="mb-1">{{email}}</h5>
                                            <p class="mb-0">{{accesshistory}}</p>
                                            <div class="d-flex align-items-center justify-content-center mt-3">
                                                <p class="btn border-secondary" style="height:200%;" @click="logout">로그아웃</p>
                                                <!-- <p><router-link :to="{name: 'people.suppliers'}" class="btn border-primary mr-2">예치금<br>10,000,000원</router-link></p> -->
                                                <!-- <p v-b-modal.modal-3 class="btn border-primary ml-2" style="height:200%;">설정</p>

                                                    <b-modal id="modal-3" size="lg" title="설정" ok-title="저장" cancel-title="닫기" @ok="savesetting">
                                                        <div class="card auth-card">
                                                            <div class="card-body p-0">
                                                                <div class="d-flex align-items-center auth-content">
                                                                <div class="col-lg-12 align-self-center">
                                                                    <div class="p-3">
                                                                        <h2 class="mb-2">자동수집설정</h2>
                                                                        <p>판매자코드({{suppliercode}})의 Supplier(or Wing) 아이디/비번을 설정하는 경우<br>
                                                                        매일 00시부터 순차적으로 SKU리스트/판매현황이 자동수집됩니다.<br>
                                                                        자동수집을 위해 2차 인증이 필요할 수 있으며, 모든 데이터는 암호화되어 저장됩니다.</p>

                                                                        <div class="mt-2 d-flex">
                                                                            <div class="form-group">
                                                                                <input type="text" class="form-control" placeholder="쿠팡아이디" v-model="id" @click="deleteid">
                                                                            </div>
                                                                            <div class="form-group ml-2">
                                                                                <input type="password" class="form-control" placeholder="쿠팡비밀번호" v-model="pw" @click="deletepw">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-modal> -->

                                                <p v-b-modal.editpayment class="btn border-primary ml-2" style="height:200%;">설정</p>

                                                    <b-modal id="editpayment" size="xl" title="설정" ok-only>

                                                            <div class="card auth-card">
                                                                <div class="card-body p-0">
                                                                    <!-- <div class="d-flex align-items-center auth-content" v-if="$store.state.user.auth.showimport">
                                                                        <div class="col-lg-12 align-self-center">
                                                                            <div class="p-3">
                                                                                <h5 class="mb-2">자동수집설정</h5>
                                                                                <p>
                                                                                "Team Member"로 부계정을 추가해 주시고, "couplus01@gmail.com"으로 등록해 주세요.<br>
                                                                                등록후에 채널톡으로 문의해 주시면, 확인 후 아이디 생성 및 자동수집서버에 해당아이디를 세팅해 드립니다.<br>
                                                                                이후에는 매일 00시부터 순차적으로 SKU리스트와 판매현황이 자동수집됩니다.<br>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
                                                                    <div class="d-flex justify-content-start p-3">
                                                                        <div v-if="showpayment" class="p-3">
                                                                            <h5>현재 구독 결제 기준일 : 매월 {{ now }}일</h5>
                                                                            <p>{{ paymentcontent }}</p>
                                                                            <b-button  :variant="btnclass" class="mb-2 mt-2" @click="changepaymentstatus">{{ paymenttext }}</b-button>

                                                                        </div>
                                                                        <div v-else class="p-3">
                                                                            <h5>결제일(만료일) : {{ expireDate }}</h5>
                                                                            <p>현재 구독 설정이 되어 있지 않습니다. 다음 결제일부터 구독결제를 진행해 주세요</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        <div class="card auth-card" v-b-modal.changePassword>
                                                            <div class="card-body p-0">
                                                                <div class="d-flex justify-content-start p-3">
                                                                    <div class="p-3">
                                                                        <h5>비밀번호 변경 ></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card auth-card" v-b-modal.geminiApi v-if="$store.state.user.auth.shownomad">
                                                            <div class="card-body p-0">
                                                                <div class="d-flex justify-content-start p-3">
                                                                    <div class="p-3">
                                                                        <h5>Gemini API 설정 ></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card auth-card" v-b-modal.datalabAPI v-if="$store.state.user.auth.shownomad">
                                                            <div class="card-body p-0">
                                                                <div class="d-flex justify-content-start p-3">
                                                                    <div class="p-3">
                                                                        <h5>네이버 데이터랩 API 설정 ></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card auth-card" v-b-modal.autoCollectSettings>
                                                            <div class="card-body p-0">
                                                                <div class="d-flex justify-content-start p-3">
                                                                    <div class="p-3">
                                                                        <h5>자동수집설정 ></h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-modal>
                                            </div>
                                            <b-modal id="changePassword" title="비밀번호 변경" hide-footer size="sm">
                                                <b-form class="position-relative">
                                                    <b-form-group class="mb-0" label-for="newPassword">
                                                        <b-form-input class="pw-input" placeholder="현재 비밀번호 입력" type="password" v-model="currentPassword" />
                                                        <b-form-input class="pw-input" placeholder="새로운 비밀번호 입력" type="password" v-model="newPassword" />
                                                        <b-form-input class="pw-input" placeholder="새로운 비밀번호 확인" type="password" v-model="checkNewPassword" />
                                                        <div class="change-button-area">
                                                            <b-button variant="outline-primary" class="change-button" @click="onClickPasswordChange">
                                                                변경
                                                            </b-button>
                                                        </div>
                                                    </b-form-group>
                                                </b-form>
                                            </b-modal>
                                            <b-modal id="geminiApi" title="Gemini API 설정" hide-footer size="sm">
                                                <b-form class="position-relative">
                                                    <b-form-group class="mb-0" label-for="gemini">
                                                        <h6><a class="text-primary" href="https://aistudio.google.com/app/apikey" target="_blank">Gemini API</a> Key를 입력해 주세요. </h6>
                                                        <b-form-input class="pw-input mt-2" placeholder="Gemini API Key 입력" type="text" v-model="geminiAPIKey" />
                                                        <b-button variant="outline-primary" class="change-button" @click="onClickGeminiAPIChange">적용</b-button>
                                                    </b-form-group>
                                                </b-form>
                                            </b-modal>
                                            <b-modal id="datalabAPI" size="xl" scrollable title="네이버 데이터랩 API설정" ok-only ok-title="닫기" class="detail-modal">
                                                <div>
                                                    <p><a href="https://developers.naver.com/apps/#/register=datalab" target="_blank"><u>네이버개발자센터</u></a>에 접속해서 아래의 순서로 API key를 받은 후 입력하고 API저장을 눌러주세요</p>
                                                    <card class="w-50 p-3">
                                                        <div class="d-flex justify-content-start align-items-center w-100">
                                                            <div class="w-25">Client ID : </div>
                                                            <div class="w-75">
                                                                <input type="text" class="form-control text-center" placeholder="Client ID" v-model="clientId">
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-content-start align-items-center w-100">
                                                            <div class="w-25">Client Secret : </div>
                                                            <div class="w-75">
                                                                <input type="text" class="form-control text-center" placeholder="Client Secret" v-model="clientSecret">
                                                            </div>
                                                        </div>
                                                        <b-button class="w-25 mt-3" variant="outline-success" @click="saveAPI">API저장</b-button>
                                                    </card>
                                                </div>
                                                <img  style="max-width:100%;" :src="require('../../../../assets/images/small/데이터랩api.png')">
                                            </b-modal>
                                            <b-modal id="autoCollectSettings" size="md" title="자동수집설정" hide-footer>
                                                <div class="card auth-card">
                                                    <div class="card-body p-0">
                                                        <div class="d-flex align-items-center auth-content">
                                                            <div class="col-lg-12 align-self-center">
                                                                <div class="p-3">
                                                                    <h5 class="mb-2">자동수집설정</h5>
                                                                    <p class="text-muted">
                                                                        ※ 쿠팡 아이디/비밀번호는 현재 PC의 브라우저에만 저장되며, 쿠플러스 서버에는 저장되지 않습니다.<br>
                                                                        ※ 자동수집 시 쿠플러스에 로그인한 상태에서 pc의 전원을 켜두어야 합니다(절전 상태x).
                                                                    </p>
                                                                    
                                                                    <div class="mt-4">
                                                                        <label>Wing/SupplierHub 계정</label>
                                                                        <div class="d-flex align-items-center">
                                                                            <div class="form-group">
                                                                                <input 
                                                                                    type="text" 
                                                                                    class="form-control" 
                                                                                    placeholder="아이디" 
                                                                                    v-model="wingId"
                                                                                    autocomplete="off"
                                                                                    autocorrect="off"
                                                                                    autocapitalize="off"
                                                                                    spellcheck="false"
                                                                                    @change="disabledAutoCollect"
                                                                                >
                                                                            </div>
                                                                            <div class="form-group ml-2">
                                                                                <input 
                                                                                    type="password" 
                                                                                    class="form-control" 
                                                                                    placeholder="비밀번호" 
                                                                                    v-model="wingPw"
                                                                                    autocomplete="new-password"
                                                                                    @change="disabledAutoCollect"
                                                                                >
                                                                            </div>
                                                                            <div class="form-group ml-2 d-flex align-items-center">
                                                                                <b-button 
                                                                                    variant="outline-primary" 
                                                                                    @click="testLogin"
                                                                                    :disabled="!wingId || !wingPw || loginTestStatus.loading"
                                                                                >
                                                                                    <template v-if="loginTestStatus.loading">
                                                                                        <b-spinner small></b-spinner>
                                                                                        <span class="ml-2">테스트 중...</span>
                                                                                    </template>
                                                                                    <span v-else>로그인 테스트</span>
                                                                                </b-button>
                                                                                <div 
                                                                                    v-if="loginTestStatus.message" 
                                                                                    class="ml-2 d-flex align-items-center"
                                                                                    :class="{'text-success': loginTestStatus.success, 'text-danger': !loginTestStatus.success}"
                                                                                >
                                                                                    <span v-if="loginTestStatus.success" class="mr-1">✓</span>
                                                                                    <span v-else class="mr-1">✗</span>
                                                                                    {{ loginTestStatus.message }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="mt-4">
                                                                        <label>자동수집 시간</label>
                                                                        <div class="form-group d-flex">
                                                                            <select class="form-control mr-2" v-model="collectTime.hour" @change="disabledAutoCollect">
                                                                                <option value="">시간</option>
                                                                                <option v-for="hour in 24" :key="hour-1" :value="String(hour-1).padStart(2, '0')">
                                                                                    {{String(hour-1).padStart(2, '0')}}
                                                                                </option>
                                                                            </select>
                                                                            <select class="form-control" v-model="collectTime.minute" @change="disabledAutoCollect">
                                                                                <option value="">분</option>
                                                                                <option v-for="minute in 60" :key="minute-1" :value="String(minute-1).padStart(2, '0')">
                                                                                    {{String(minute-1).padStart(2, '0')}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="mt-4">
                                                                        <label>수집 데이터 선택</label>
                                                                        <div class="form-group">
                                                                            <div class="custom-control custom-checkbox mb-2">
                                                                                <input type="checkbox" class="custom-control-input" id="rankTracking" v-model="collectItems.rankTracking" @change="disabledAutoCollect">
                                                                                <label class="custom-control-label" for="rankTracking">랭킹추적</label>
                                                                            </div>
                                                                            <div class="custom-control custom-checkbox mb-2">
                                                                                <input type="checkbox" class="custom-control-input" id="rocketGrowthProducts" v-model="collectItems.rocketGrowthProducts" @change="disabledAutoCollect">
                                                                                <label class="custom-control-label" for="rocketGrowthProducts">로켓그로스 상품관리</label>
                                                                            </div>
                                                                            <div class="custom-control custom-checkbox mb-2">
                                                                                <input type="checkbox" class="custom-control-input" id="rocketGrowthSales" v-model="collectItems.rocketGrowthSales" @change="disabledAutoCollect">
                                                                                <label class="custom-control-label" for="rocketGrowthSales">로켓그로스 판매현황/SCM</label>
                                                                            </div>
                                                                            <div class="custom-control custom-checkbox mb-2">
                                                                                <input type="checkbox" class="custom-control-input" id="rocketDeliverySKU" v-model="collectItems.rocketDeliverySKU">
                                                                                <label class="custom-control-label" for="rocketDeliverySKU">로켓배송 상품SKU수집</label>
                                                                            </div>
                                                                            <div class="custom-control custom-checkbox mb-2">
                                                                                <input type="checkbox" class="custom-control-input" id="rocketDeliveryOrder" v-model="collectItems.rocketDeliveryOrder">
                                                                                <label class="custom-control-label" for="rocketDeliveryOrder">로켓배송 발주서 수집</label>
                                                                            </div>
                                                                            <div class="custom-control custom-checkbox mb-2">
                                                                                <input type="checkbox" class="custom-control-input" id="rocketDeliverySales" v-model="collectItems.rocketDeliverySales">
                                                                                <label class="custom-control-label" for="rocketDeliverySales">로켓배송 판매현황 수집</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="mt-4">
                                                                        <div class="d-flex justify-content-between align-items-center mb-3">
                                                                            <label class="mb-0">자동수집 상태</label>
                                                                            <div class="d-flex align-items-center">
                                                                                <b-form-checkbox
                                                                                    v-model="autoCollectEnabled"
                                                                                    switch
                                                                                    @change="handleAutoCollectToggle"
                                                                                    class="mr-2"
                                                                                >
                                                                                    {{ autoCollectEnabled ? '활성화' : '비활성화' }}
                                                                                </b-form-checkbox>
                                                                            </div>
                                                                        </div>
                                                                        <small class="text-muted">
                                                                            {{ autoCollectEnabled ? 
                                                                                `매일 ${collectTime.hour}시 ${collectTime.minute}분에 선택된 데이터가 자동으로 수집됩니다.` : 
                                                                                '자동수집이 중지되어 있습니다.' }}
                                                                        </small>
                                                                    </div>

                                                                    <div class="mt-4 d-flex justify-content-center">
                                                                        <b-button variant="primary" @click="saveAutoCollectSettings" class="mr-2">설정 저장</b-button>
                                                                        <b-button 
                                                                            variant="success" 
                                                                            @click="startCollectNow"
                                                                            :disabled="!loginTestStatus.success || !Object.values(collectItems).some(v => v)"
                                                                        >
                                                                            지금수집
                                                                        </b-button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </b-collapse>
            </div>
        </nav>
    </div>
</div>
<!-- <b-modal id="new-order" centered hide-footer hide-header hide-header-close>
    <div class="popup text-left">
        <h4 class="mb-3">New Order</h4>
        <div class="content create-workform bg-body">
            <div class="pb-3">
                <label class="mb-2">{{email}}</label>
                <input type="text" class="form-control" placeholder="Enter Name or Email">
            </div>
            <div class="col-lg-12 mt-4">
                <div class="d-flex flex-wrap align-items-ceter justify-content-center">
                    <div class="btn btn-primary mr-4" data-dismiss="modal" @click="$bvModal.hide('new-order')">Cancel</div>
                    <div class="btn btn-outline-primary" data-dismiss="modal" @click="$bvModal.hide('new-order')">Create</div>
                </div>
            </div>
        </div>
    </div>
</b-modal> -->
<div 
    v-if="autoCollectMessage" 
    class="auto-collect-toast"
>
    {{ autoCollectMessage }}
</div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
    name:"HeaderStyle1",
    computed : {
        ...mapGetters({
            companyid: 'companyid',
            suppliercode: 'suppliercode',
            appName: 'appName',
            logo:'logo',
            accesshistory: 'accesshistory',
            email:'email',
            image1:'image1',
            expireDate: 'expireDate'
        })
    },
    data(){
		return{
            showSellerInfo:true,
			id:'쿠팡아이디',
            pw:'쿠팡비번',
            now:'',
            paymenttext:'',
            paymentcontent:'',
            btnclass:'',
            showpayment:true,
            currentPassword: '',
            newPassword: '',
            checkNewPassword: '',
            geminiAPIKey:'',
            clientId: this.$store.state.settings[0]?.clientId ? this.$store.state.settings[0].clientId : '',
			clientSecret: this.$store.state.settings[0]?.clientSecret ? this.$store.state.settings[0].clientSecret : '',
            wingId: '',
            wingPw: '',
            collectTime: {
                hour: '',
                minute: ''
            },
            collectItems: {
                rankTracking: false,
                rocketGrowthProducts: false,
                rocketGrowthSales: false,
                rocketDeliverySKU: false,
                rocketDeliveryOrder: false,
                rocketDeliverySales: false
            },
            loginTestStatus: {
                loading: false,
                success: false,
                message: ''
            },
            messageEventHandler: null,
            autoCollectEnabled: false,
            autoCollectMessage: '',
		}
	},
    mounted(){
        this.setpaymenttext();
        this.setShowSellerInfo();
        this.setGeminiAPIKey();
        this.loadAutoCollectSettings();

        // // 메시지 이벤트 리스너 추가
        // this.messageEventHandler = async(event) => {
        //     // coupangLoginTest 응답 처리
        //     if (event.data.res === 'coupangLoginTest') {
        //         this.loginTestStatus = {
        //             loading: false,
        //             success: event.data.result,
        //             message: event.data.result ? '로그인 성공' : event.data.msg
        //         };
        //     }
        //     // (추가) autoCollectStatus 메시지 처리
        //     if (event.data.type === 'autoCollectStatus') {
        //         console.log(event.data)
        //         this.updateAutoCollectStatus(event.data.message);
        //     }
        // };
        // window.addEventListener('message', this.messageEventHandler);
    },
    created() {
        // 메시지 이벤트 리스너 추가
        this.messageEventHandler = async(event) => {
            // coupangLoginTest 응답 처리
            if (event.data.res === 'coupangLoginTest') {
                this.loginTestStatus = {
                    loading: false,
                    success: event.data.result,
                    message: event.data.result ? '로그인 성공' : event.data.msg
                };
            }
            // (추가) autoCollectStatus 메시지 처리
            if (event.data.type === 'autoCollectStatus') {
                console.log(event.data);
                
                // 우선 지금 들어온 message를 화면에 표시
                this.updateAutoCollectStatus(event.data.message);

                // 만약 "모든 수집 작업이 완료되었습니다." 라는 메시지라면, 5초 후에 다음 수집 시각을 보여줌
                if (event.data.message === '모든 수집 작업이 완료되었습니다.') {
                    if(this.autoCollectEnabled){
                        setTimeout(() => {
                            this.autoCollectMessage = `다음 수집 시각 : ${this.collectTime.hour}시 ${this.collectTime.minute}분`;
                        }, 5000);
                    } else {
                        setTimeout(() => {
                            this.autoCollectMessage = "";
                        }, 5000);
                    }
                }
            }

            if (event.data.res && (event.data.res === 'growthdbratio' || event.data.res === 'growthDbRatio')){
                var loadingtext = event.data.text + event.data.ratio.toFixed(0) + '%'
                if(this.autoCollectEnabled){
                    this.updateAutoCollectStatus(loadingtext);
                }
            }
            if (event.data.res && event.data.res === 'keywordanalysisstatusratio') {
				loadingtext = event.data.text + '...(' + event.data.status + ")";
				if(this.autoCollectEnabled){
                    this.updateAutoCollectStatus(loadingtext);
                }
			}
        };
        window.addEventListener('message', this.messageEventHandler);
    },
    beforeDestroy() {
        // 컴포넌트 제거 시 이벤트 리스너 정리
        window.removeEventListener('message', this.messageEventHandler);
    },
    methods: {
        startCollectNow() {
            if (!this.loginTestStatus.success) {
                alert('먼저 로그인 테스트를 성공적으로 완료해주세요.');
                return;
            }

            if (!Object.values(this.collectItems).some(v => v)) {
                alert('하나 이상의 수집 데이터를 선택해주세요.');
                return;
            }

            // 수집 시작 메시지를 extension에 전송
            window.postMessage({
                greeting: "startCollectNow",
                settings: {
                    id: this.wingId,
                    pw: this.wingPw,
                    items: this.collectItems
                }
            }, "*");

            this.$bvModal.hide('autoCollectSettings');
            this.autoCollectMessage = '수동 수집이 시작되었습니다.';

            // 수집 진행 상태는 기존 messageEventHandler에서 처리됨
        },
        disabledAutoCollect() {
            if(this.autoCollectEnabled){
                this.autoCollectEnabled = false;
                this.autoCollectMessage = '';
                this.handleAutoCollectToggle(this.autoCollectEnabled);
            }
        },
        async saveAPI(){
			try{
				var result = await axios.post('/api/setDatalabApi',{clientId:this.clientId,clientSecret:this.clientSecret});
				if(result.data.요청결과 == 'success'){
					var obj = this.deepClone(this.$store.state.settings[0]);
					obj.clientId = this.clientId;
					obj.clientSecret = this.clientSecret;
					this.$store.commit('settingsupdate',[obj])
					alert('저장완료')
				} else {
					alert('저장실패(510)');
					console.log(result.data.result);
				}
			}catch(e){
				alert('저장실패(514)')
				console.log(e)
			}
		},
        deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
        setGeminiAPIKey(){
            console.log(this.$store.state.settings[0])
            if(this.$store.state.user.auth.showAI && this.$store.state.settings[0].geminiAPIKey){
                this.geminiAPIKey = this.$store.state.settings[0].geminiAPIKey;
            }
        },
        setShowSellerInfo(){
            if(this.$store.state.user.auth.showSellerInfo === false){
                this.showSellerInfo = false;
            }
        },
        setpaymenttext(){
            this.now = this.$store.state.user.paydate;
            if(this.now){
                this.showpayment = true;
            } else {
                this.showpayment = false;
            }
            if(this.$store.state.user.nextpayment){
                this.paymenttext = '구독취소';
                this.paymentcontent = '구독취소 시 다음달 결제일 부터 구독결제가 종료됩니다.'
            } else {
                this.paymenttext = '구독시작';
                this.paymentcontent = '구독시작 시 다음달 결제일 부터 구독결제가 시작됩니다.'
            }
            this.changebtnclass();
        },
        changebtnclass(){
            if(this.paymenttext == '구독취소'){
                this.btnclass = 'outline-secondary';
            } else {
                this.btnclass = 'outline-success';
            }
        },
        changepaymentstatus(){
            if(this.paymenttext == '구독취소'){
                if(!confirm('구독취소 시 해당 아이디의 기존권한 및 모든 데이터가 삭제됩니다. 계속하시겠습니까?')){
                    return
                }
            }
            axios.post('/api/login/changepaymentstatus',{nextpayment : this.$store.state.user.nextpayment})
            .then((result)=>{
                if(result.data.요청결과 == 'success'){
                    alert(result.data.message);
                    this.$store.commit('userupdate',result.data.userinfo);
                    this.setpaymenttext();
                } else {
                    alert(result.data.message);
                }
            })
            .catch((e)=>{
                console.log(e)
            })
        },
        deleteid(){this.id = ''},
        deletepw(){this.pw = ''},
        logout() {
            axios.post('/api/logout')
            .then((result) =>{
                console.log(result)
                console.log(result.data)
                console.log(result.data.loginstatus)
                if (result.data.loginstatus == false) {
                    this.$store.commit('resetState');
                    this.$router.push({name: 'auth.login'})
                }
            })
            .catch(console.log)
        },
        savesetting(){
            axios.post('/api/savesetting',{coupangid:this.id,coupangpw:this.pw})
            .then((result) =>{
                console.log(result)
                if (result.data.요청결과 == '저장완료') {
                    alert('서버의 쿠팡 접속을 위해 수일 이내에 2차 인증 요청으로 연락 드릴 예정입니다.');
                }
            })
            .catch(console.log)
        },
        onClickPasswordChange() {
          if (this.newPassword !== this.checkNewPassword) {
              alert('새 비밀번호와 비밀번호 확인이 일치하지 않습니다.')
          } else {
              axios.post('/api/auth/change-password', {currentPwd: this.currentPassword, newPwd: this.newPassword, email: this.$store.state.user.email})
                  .then((response) => {
                      if (response.data.result === 'success') {
                          alert('비밀번호가 변경됐습니다.')
                          this.$bvModal.hide('changePassword')
                          this.currentPassword = ''
                          this.newPassword = ''
                          this.checkNewPassword = ''
                      } else {
                          alert(response.data.message)
                          console.log(response.data)
                      }
                  })
                  .catch((error) => {
                      alert(error.response.data)
                  })
          }
        },
        async onClickGeminiAPIChange() {
            if (!this.geminiAPIKey) {
                alert('Gemini API를 입력해 주세요')
                return
            }
            try{
                var res = await axios.post('/api/setGeminiApi', {key: this.geminiAPIKey})
                if (res.data.요청결과 === '저장완료') {
                    alert('Gemini API Key가 적용 되었습니다.')
                    this.$store.commit('settingsupdateparameter',{key:'geminiAPIKey',value:this.geminiAPIKey});
                    console.log(this.$store.state.settings)
                    this.$bvModal.hide('geminiApi');
                } else {
                    alert(res.data.요청결과)
                    console.log(res.data)
                } 
            } catch(error){
                alert(error.response.data)
            }
        },
        // 암호화 함수
        encrypt(text) {
            // 간단한 양방향 암호화를 위해 btoa 사용
            return btoa(text);
        },

        // 복호화 함수
        decrypt(encoded) {
            try {
                return atob(encoded);
            } catch (e) {
                console.error('Decryption failed:', e);
                return '';
            }
        },

        // 설정 저장
        saveAutoCollectSettings() {
            // 활성화 상태일 때 필수 조건 한번 더 체크
            if (this.autoCollectEnabled) {
                if (!this.loginTestStatus.success || !this.collectTime.hour || !this.collectTime.minute || !Object.values(this.collectItems).some(v => v)) {
                    this.autoCollectEnabled = false;
                    alert('자동수집 활성화를 위한 필수 조건이 충족되지 않아 비활성화되었습니다.');
                    return;
                }
            }

            const settings = {
                id: this.encrypt(this.wingId),
                pw: this.encrypt(this.wingPw),
                time: this.collectTime,
                items: this.collectItems,
                enabled: this.autoCollectEnabled
            };

            localStorage.setItem('autoCollectSettings', JSON.stringify(settings));
            this.$bvModal.hide('autoCollectSettings');

            if (this.autoCollectEnabled) {
                this.autoCollectMessage = `자동수집 설정이 저장되었습니다. ${this.collectTime.hour}시 ${this.collectTime.minute}분에 수집이 시작됩니다.`;
            } else {
                this.autoCollectMessage = '자동수집 설정이 저장되었습니다. 수집이 중지되었습니다.';
            }

            if (!this.autoCollectEnabled) {
                setTimeout(() => {
                    this.autoCollectMessage = '';
                }, 5000);
            }

            alert('자동수집 설정이 저장되었습니다.');
        },
        // 설정 불러오기
        loadAutoCollectSettings() {
            try {
                const saved = localStorage.getItem('autoCollectSettings');
                if (saved) {
                    const settings = JSON.parse(saved);
                    this.wingId = this.decrypt(settings.id);
                    this.wingPw = this.decrypt(settings.pw);
                    
                    // time 형식 확인 및 변환
                    if (settings.time) {
                        if (typeof settings.time === 'object' && settings.time.hour && settings.time.minute) {
                            // 이미 올바른 형식인 경우
                            this.collectTime = settings.time;
                        } else if (typeof settings.time === 'string') {
                            // 이전 형식(문자열)인 경우 새 형식으로 변환
                            this.collectTime = {
                                hour: settings.time,
                                minute: '00'
                            };
                        } else {
                            // 기본값 설정
                            this.collectTime = {
                                hour: '',
                                minute: ''
                            };
                        }
                    }
                    
                    this.collectItems = settings.items;
                    this.autoCollectEnabled = settings.enabled || false;
                    
                    if (this.autoCollectEnabled && this.collectTime.hour && this.collectTime.minute) {
                        this.autoCollectMessage = `자동수집이 활성화되었습니다. ${this.collectTime.hour}시 ${this.collectTime.minute}분에 수집이 시작됩니다.`;
                    }
                }
            } catch (e) {
                console.error('Failed to load settings:', e);
                // 오류 발생 시 기본값 설정
                this.collectTime = {
                    hour: '',
                    minute: ''
                };
            }
        },
        async testLogin() {
            if (!this.wingId || !this.wingPw) {
                return;
            }

            // 상태 초기화
            this.loginTestStatus = {
                loading: true,
                success: false,
                message: ''
            };

            try {
                window.postMessage({
                    greeting: "coupangLoginTest",
                    id: this.wingId,
                    pw: this.wingPw,
                    supplierCode: this.$store.state.user.suppliercode,
                }, "*");
            } catch (error) {
                this.loginTestStatus = {
                    loading: false,
                    success: false,
                    message: '테스트 시작 실패'
                };
                console.error('Login test initiation failed:', error);
            }
        },
        
        // 자동수집 상태 메시지 업데이트 함수
        updateAutoCollectStatus(message) {
            this.autoCollectMessage = message;
            // 메시지 표시 후 5초 뒤 제거
        },

        // handleAutoCollectToggle 메소드 수정
        handleAutoCollectToggle(checked) {
            if (checked) {
                if (!this.loginTestStatus.success) {
                    alert('먼저 로그인 테스트를 성공적으로 완료해주세요.');
                    this.autoCollectEnabled = false;
                    return;
                }

                if (this.collectTime.hour === '' || this.collectTime.minute === '') {
                    alert('자동수집 시간을 설정해주세요.');
                    this.autoCollectEnabled = false;
                    return;
                }

                if (!Object.values(this.collectItems).some(v => v)) {
                    alert('하나 이상의 수집 데이터를 선택해주세요.');
                    this.autoCollectEnabled = false;
                    return;
                }

                this.autoCollectMessage = `자동수집이 활성화되었습니다. ${this.collectTime.hour}시 ${this.collectTime.minute}분에 수집이 시작됩니다.`;

                window.postMessage({
                    greeting: "autoCollectToggle",
                    enabled: true,
                    settings: {
                        id: this.wingId,
                        pw: this.wingPw,
                        time: this.collectTime,
                        items: this.collectItems
                    }
                }, "*");
            } else {
                this.autoCollectMessage = '자동수집이 비활성화되었습니다.';
                
                window.postMessage({
                    greeting: "autoCollectToggle",
                    enabled: false
                }, "*");

                setTimeout(() => {
                    this.autoCollectMessage = '';
                }, 5000);
            }
        },
        disableAutoCollect(message) {
            this.autoCollectEnabled = false;
            this.autoCollectMessage = message;
            
            // Chrome extension에 비활성화 메시지 전송
            window.postMessage({
                greeting: "autoCollectToggle",
                enabled: false
            }, "*");

            // 5초 후 메시지 제거
            setTimeout(() => {
                this.autoCollectMessage = '';
            }, 5000);
        }

    }


}
</script>

<style scoped lang="scss">
.pw-input {
    margin-bottom: 25px;
}
.change-button-area {
    display: flex;
    justify-content: center;
    align-items: center;
    .change-button {
        width: 15%;
    }
}
#changePassword {
    max-width: 50%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/* (추가) 자동수집 상태 토스트 메시지 스타일 */
.auto-collect-toast {
    position: fixed; /* 화면 고정 위치 */
    top: 80px; /* 헤더 바로 아래 위치 (필요에 따라 조정) */
    right: 20px;
    z-index: 10000; /* 다른 요소들보다 위에 표시 */
    background-color: rgba(0, 0, 0, 0.7); /* 반투명 배경 */
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.3);
    /* 애니메이션 제거하여 메시지가 지속적으로 표시되도록 함 */
    /* animation: fadein 0.5s, fadeout 0.5s 4.5s; */
    transition: opacity 0.5s;
}

.auto-collect-toast-enter-active, .auto-collect-toast-leave-active {
    transition: opacity 0.5s;
}
.auto-collect-toast-enter, .auto-collect-toast-leave-to /* .leave-active for <2.1.8 */ {
    opacity: 0;
}
</style>
